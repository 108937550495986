import { Alert, Container } from "react-bootstrap";

import React from "react";

const Projects = () => {
    return (
        <Container>
            <Alert variant={"info"}>Coming soon!</Alert>
        </Container>
    )
}

export default Projects;