import 'css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Navigate, Route, Routes } from "react-router-dom";

import AboutMe from 'components/AboutMe';
import {Container} from "react-bootstrap"
import Home from 'components/Home';
import Learning from 'components/Learning';
import Nav from 'components/Nav'
import Projects from 'components/Projects'
import React from "react"

const App = () => {
  return (
    <div className="App">
      <Nav />
      <Container fluid className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/learning" element={<Learning />}/>
            <Route path="/projects" element={<Projects />}/>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Container>

      
    </div>
  );
}

export default App;
