import 'css/Nav.css'

import { Container, Nav, Navbar } from "react-bootstrap"

import { NavLink } from 'react-router-dom';
import React from "react"

const NavContainer = () => (
<Navbar bg="light" expand="md" sticky="top">
  <Container>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto ms-auto">
        <Nav.Link as={NavLink} className='px-5' to="/home">Home</Nav.Link>
        <Nav.Link as={NavLink} className='px-5' to="/learning#">Learning</Nav.Link>
        <Nav.Link as={NavLink} className='px-5' to="/projects">Projects</Nav.Link>
        <Nav.Link as={NavLink} className='px-5' to="/about">About</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
)

export default NavContainer