import "css/Home.css"

import { Button, Card, Col, Container, Row } from "react-bootstrap"
import React, { useEffect } from "react"

import { Link } from "react-router-dom"
import { Profile } from 'img'
import { useLocation } from "react-router-dom";

const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <Container className="pb-3 pt-3">
      <h1>Lenard Ong</h1>
      <Row>
        <Col md={12} className="pt-4 pb-4">
          <Card className="home__cards">
            <Card.Body >
              <Row>
                <Col xs={12} md={8} className='pt-2 pb-2'>
                  <Card.Text className="home__introduction">
                    I am a Backend Software Engineer working in the Recommendation team in Shopee.
                    Together with Machine Learning Engineers, I work to cut through the noise of ambiguity using the scale of big data to personalise recommendations.
                    Problems of such scale are often difficult, and always very highly complex.
                    To address this, I design and build scalable architectures so that we can serve millions of users on a daily basis.
                    <br /> <br />
                    Prior to my time as a Software Engineer I worked as a client facing Technology Consultant at EY,
                    recommending and designing the latest and greatest to address key business pain points.
                    Through many client presentations and discussions I learned to deal with difficult stakeholders,
                    communicate ideas efficiently and effectively, and make snazzy slide decks.
                    <br /> <br />
                    For my Masters, I read Engineering Science at the University of Oxford, covering modules such as Machine Learning,
                    Computer Vision, Robotics, Advanced Probability, Multivariable Control, Non-Linear Control, Aerodynamics, and Thermodynamics.
                  </Card.Text>
                </Col>
                <Col md={4} xs={12} className='pt-2 pb-2'>
                  <Card.Img variant="top" src={Profile} className="home__profile_image"/>
                  <Card.Text>That&apos;s me in Japan, 2020</Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <Card className="home__cards">
            <Card.Body>
              <Card.Title>Learning</Card.Title>
              <Card.Text className="home__introduction">
              Learning as a hobby is the superset of all hobbies. 
              If there&apos;s one thing about me that you should know, I love learning.
              </Card.Text>
              <Link to="/Learning">
                <Button variant="outline-primary">Find out more</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className='home__cards'>
            <Card.Body className='home__cards_body_links'>
              <Card.Title>Projects</Card.Title>
              <Card.Text>
                Coming soon!
              </Card.Text>
              <Card.Text>
                Applied learning with some of my personal projects.
              </Card.Text>
              <Link className='home__cards_links' to="/projects">
                <Button variant="outline-primary">Find out more</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="home__cards">
            <Card.Body className='home__cards_body_links'>
              <Card.Title>About Me</Card.Title>
              <Card.Text>
                Work in Progress!
              </Card.Text>
              <Card.Text>
                Information about me. Like a CV but online.
              </Card.Text>
              <Link className='home__cards_links' to="/about">
                <Button variant="outline-primary">Take me there!</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )

}

export default Home